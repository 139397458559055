<mat-toolbar color="primary">
  <div class="header-group-left">
    <img src="assets/ecs-logo.png" alt="ECS Limited corporate logo" class="ecs-logo">
    <h2 class="header-app-title">{{ selectedPage.title }}</h2>
    <p class="header-app-subtitle">Pointfinder 4.0.03.03.25</p>
  </div>
  <div class="header-group-right">
    <button mat-button><a [routerLink]="linkPage.route">{{ linkPage.title }}</a></button>
    <p class="divider" *ngIf="authService && authService.userName">|</p>
    <p class="header-app-user">{{ authService.userName }}</p>
  </div>
</mat-toolbar>
<router-outlet></router-outlet>
